/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TypoGraphy from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    title: {
        flexGrow: 1,
    },
    appBar: {
        position: 'sticky',
        top: 0,
    },
}));

interface OwnProps {
    showAction: boolean;
    action: () => void;
    cancel: () => void;
    undo: () => void;
}

const Nav: FC<OwnProps> = (props: OwnProps) => {
    const { showAction, action, cancel, undo } = props;
    const classes = useStyles();

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <TypoGraphy className={classes.title} variant="h6">
                    PhotoID
                </TypoGraphy>
                {showAction && (
                    <div>
                        <Button color="inherit" onClick={cancel}>
                            Cancel
                        </Button>
                        <Button color="inherit" onClick={undo}>
                            Undo
                        </Button>
                        <Button color="inherit" onClick={action}>
                            Save
                        </Button>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Nav;
