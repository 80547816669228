/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FC } from 'react';
import RouterComponent from './components/Router';
import { SnackbarProvider } from 'notistack';

interface OwnProps {}

const App: FC<OwnProps> = (props: OwnProps) => {

    return (
        <div className="app">
            <SnackbarProvider>
                <RouterComponent />
            </SnackbarProvider>
        </div>
    );
}

export default App;
