/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { throttle } from 'lodash';

import { BoundingBox, Size } from '../../types/predictions';
import { ResizeType } from '../../types/position';

interface Coords {
    x: number;
    y: number;
}

interface OwnState {
    originalPos: Coords;
    resizeType: ResizeType | null;
}

interface OwnProps {
    setCurrentBox: Function;
    setPositionFunctions: Function;
    currentBox: { index: number; box: BoundingBox };
    finishMovingBox: Function;
    boxes: BoundingBox[];
    imageSize: Size;
}

const INITIAL_STATE: OwnState = {
    originalPos: { x: 0, y: 0 },
    resizeType: null,
};

class PositionManager extends Component<OwnProps, OwnState> {
    ref: any;
    throttledOnMove: (evt: any) => void;
    constructor(props: OwnProps) {
        super(props);
        this.state = INITIAL_STATE;
        this.ref = null;
        this.throttledOnMove = throttle(this.onMove, 25);
    }

    componentDidMount() {
        const { setPositionFunctions } = this.props;
        setPositionFunctions(this.onStartMovingBox, this.onStartResizingBox);
        window.addEventListener('mousemove', this.throttledOnMove);
        window.addEventListener('mouseup', this.onEndMovingBox);
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.throttledOnMove);
        window.removeEventListener('mouseup', this.onEndMovingBox);
    }

    onStartMovingBox = (evt: any, index: number) => {
        const { setCurrentBox, boxes } = this.props;
        this.setState({ originalPos: this.getCoords(evt), resizeType: null });
        setCurrentBox({ index, box: boxes[index] }, index);
    };

    onStartResizingBox = (evt: any, type: ResizeType, index: number) => {
        const { setCurrentBox, boxes } = this.props;
        this.setState({ originalPos: this.getCoords(evt), resizeType: type });
        setCurrentBox({ index, box: boxes[index] }, index);
    };

    getCoords = (evt: any) => {
        const rect = this.ref.getBoundingClientRect();
        let x = evt.clientX - rect.left;
        let y = evt.clientY - rect.top;
        return { x, y };
    };

    handleMove = (displacementX: number, displacementY: number) => {
        const { currentBox, setCurrentBox, boxes, imageSize } = this.props;
        const leftX =
            (boxes[currentBox.index].coord[0] < boxes[currentBox.index].coord[2]
                ? boxes[currentBox.index].coord[0]
                : boxes[currentBox.index].coord[2]) + displacementX;
        let overflowLeftX = leftX;
        if (overflowLeftX > 0) {
            overflowLeftX = 0;
        }
        const topY =
            (boxes[currentBox.index].coord[1] < boxes[currentBox.index].coord[3]
                ? boxes[currentBox.index].coord[1]
                : boxes[currentBox.index].coord[3]) + displacementY;
        let overflowTopY = topY;
        if (overflowTopY > 0) {
            overflowTopY = 0;
        }
        const rightX =
            (boxes[currentBox.index].coord[2] > boxes[currentBox.index].coord[0]
                ? boxes[currentBox.index].coord[2]
                : boxes[currentBox.index].coord[0]) + displacementX;
        let overflowRightX = imageSize.width - rightX;
        if (overflowRightX > 0) {
            overflowRightX = 0;
        }
        const bottomY =
            (boxes[currentBox.index].coord[3] > boxes[currentBox.index].coord[1]
                ? boxes[currentBox.index].coord[3]
                : boxes[currentBox.index].coord[1]) + displacementY;
        let overflowBottomY = imageSize.height - bottomY;
        if (overflowBottomY > 0) {
            overflowBottomY = 0;
        }
        setCurrentBox({
            index: currentBox.index,
            box: {
                ...currentBox.box,
                coord: [
                    leftX - overflowLeftX + overflowRightX,
                    topY - overflowTopY + overflowBottomY,
                    rightX - overflowLeftX + overflowRightX,
                    bottomY - overflowTopY + overflowBottomY,
                ],
            },
        });
    };

    handleResize = (displacementX: number, displacementY: number) => {
        const { boxes, currentBox, setCurrentBox, imageSize } = this.props;
        const { resizeType } = this.state;
        let leftX = boxes[currentBox.index].coord[0] + displacementX;
        if (leftX <= 0) {
            leftX = 0;
        }
        if (leftX >= imageSize.width) {
            leftX = imageSize.width;
        }

        let topY = boxes[currentBox.index].coord[1] + displacementY;
        if (topY <= 0) {
            topY = 0;
        }
        if (topY >= imageSize.height) {
            topY = imageSize.height;
        }

        let rightX = boxes[currentBox.index].coord[2] + displacementX;
        if (rightX <= 0) {
            rightX = 0;
        }
        if (rightX >= imageSize.width) {
            rightX = imageSize.width;
        }

        let bottomY = boxes[currentBox.index].coord[3] + displacementY;
        if (bottomY <= 0) {
            bottomY = 0;
        }
        if (bottomY >= imageSize.height) {
            bottomY = imageSize.height;
        }

        switch (resizeType) {
            case ResizeType.TOP_LEFT:
                bottomY = boxes[currentBox.index].coord[3];
                rightX = boxes[currentBox.index].coord[2];
                break;
            case ResizeType.TOP_RIGHT:
                bottomY = boxes[currentBox.index].coord[3];
                leftX = boxes[currentBox.index].coord[0];
                break;
            case ResizeType.BOTTOM_LEFT:
                topY = boxes[currentBox.index].coord[1];
                rightX = boxes[currentBox.index].coord[2];
                break;
            case ResizeType.BOTTOM_RIGHT:
                topY = boxes[currentBox.index].coord[1];
                leftX = boxes[currentBox.index].coord[0];
                break;
            default:
        }
        setCurrentBox({
            index: currentBox.index,
            box: {
                ...currentBox.box,
                coord: [leftX, topY, rightX, bottomY],
            },
        });
    };

    onMove = (evt: any) => {
        const { currentBox, imageSize } = this.props;
        const { resizeType, originalPos } = this.state;
        evt.stopPropagation();
        evt.preventDefault();
        if (currentBox.index !== -1) {
            const rect = this.ref.getBoundingClientRect();
            const current = this.getCoords(evt);
            const displacementX = ((current.x - originalPos.x) * imageSize.width) / rect.width;
            const displacementY = ((current.y - originalPos.y) * imageSize.height) / rect.height;
            if (resizeType === null) {
                this.handleMove(displacementX, displacementY);
            } else {
                this.handleResize(displacementX, displacementY);
            }
        }
    };

    onEndMovingBox = () => {
        const { finishMovingBox } = this.props;
        finishMovingBox();
    };

    render() {
        const { children } = this.props;
        return (
            <div className="image-container" ref={(r) => (this.ref = r)}>
                {children}
            </div>
        );
    }
}

export default PositionManager;
