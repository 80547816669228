/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

interface OwnProps {
    blocking: boolean;
}

const BlockUI: FC<OwnProps> = (props: OwnProps) => {
    if (!props.blocking) {
        return <React.Fragment />;
    } else {
        return (
            <div className="block-ui-container">
                <div className="block-ui-overlay" />
                <div className="block-ui-message-container">
                    <div className="loading-indicator">
                        <CircularProgress />
                    </div>
                </div>
            </div>
        );
    }
};

BlockUI.defaultProps = {
    blocking: false,
};

export default BlockUI;
