/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import { SizeMe } from 'react-sizeme';
import { isEqual } from 'lodash';
import axios from 'axios';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import DropdownPin from '../elements/DropdownPin';
import { BoundingBox, PredictionResult, Size } from '../../types/predictions';
import DrawingManager from '../containers/DrawingManager';
import PositionManager from '../containers/PositionManager';
import { submitURL } from '../../services/labels';

enum ActionTypes {
    ADD,
    DELETE,
    MOVE,
    RESIZE,
}

interface Action {
    action: ActionTypes;
    box: BoundingBox;
    index?: number;
}

interface OwnState {
    image: string;
    image_size: Size;
    boxes: BoundingBox[];
    drawing: boolean;
    history: Action[];
    currentBox: { index: number; box: BoundingBox };
    onStartMovingBox: Function;
    onStartResizingBox: Function;
}

interface OwnProps extends WithSnackbarProps {
    uploadedFile: File | null;
    prediction: PredictionResult | null;
    setNavAction: Function;
    resetLabeller: Function;
}

const INITIAL_STATE: OwnState = {
    image: '',
    image_size: { width: 0, height: 0, depth: 0 },
    boxes: [],
    drawing: false,
    history: [],
    currentBox: { index: -1, box: { coord: [0, 0, 0, 0], label: '' } },
    onStartMovingBox: () => {},
    onStartResizingBox: () => {},
};

class Labeller extends Component<OwnProps, OwnState> {
    state = INITIAL_STATE;

    componentDidMount() {
        const { prediction, uploadedFile, setNavAction } = this.props;

        if (prediction) {
            this.setState({
                image: URL.createObjectURL(uploadedFile),
                boxes: prediction.bounding_boxes,
                image_size: prediction.image,
            });
        }
        setNavAction(this.handleSubmit, this.undo);
    }

    deleteBox = (index: number) => {
        const { boxes, history } = this.state;
        const box = boxes.splice(index, 1);
        history.push({ action: ActionTypes.DELETE, box: box[0] });
        this.setState({ boxes, history });
    };

    appendBox = (box: BoundingBox, size: any) => {
        const { boxes, image_size, history } = this.state;
        box.coord = box.coord.map((value: number) => (value * image_size.width) / size.width);
        box.show = true;
        boxes.push(box);
        history.push({ action: ActionTypes.ADD, box });
        this.setState({ boxes, history });
    };

    handleSubmit = () => {
        const { boxes, image_size } = this.state;
        const { uploadedFile, resetLabeller, enqueueSnackbar } = this.props;

        const convertedBoxes = boxes.map((box) => {
            const coords = box.coord.map((coord) => Math.round(coord));
            var lastIndex = box.label.lastIndexOf(' ');
            return {
                // remove prediction percentage (xx%) from labels
                label: box.label.endsWith('%)') ? box.label.substring(0, lastIndex) : box.label,
                coord: [
                    coords[0] < coords[2] ? coords[0] : coords[2],
                    coords[1] < coords[3] ? coords[1] : coords[3],
                    coords[0] > coords[2] ? coords[0] : coords[2],
                    coords[1] > coords[3] ? coords[1] : coords[3],
                ],
            };
        });

        //create json
        const resp = {
            image: {
                width: image_size.width,
                height: image_size.height,
                depth: image_size.depth,
            },
            bounding_boxes: convertedBoxes.map((item) => {
                return { coord: item.coord, label: item.label };
            }),
        };

        const json = JSON.stringify(resp);
        const blob = new Blob([json], {
            type: 'application/json',
        });

        const fd = new FormData();

        if (uploadedFile != null) {
            fd.append('file', uploadedFile);
        }

        fd.append('prediction', blob);

        axios
            .post(submitURL(), fd)
            .then(() => {
                enqueueSnackbar('Labels submitted.', { variant: 'success' });
                resetLabeller();
            })
            .catch(() => {
                enqueueSnackbar('Error submitting labels.', { variant: 'error' });
            });
    };

    undo = () => {
        const { history, boxes } = this.state;
        const latest = history.pop();
        if (latest) {
            switch (latest.action) {
                case ActionTypes.ADD:
                    const found = boxes.findIndex((box) => isEqual(box, latest.box));
                    if (found !== -1) {
                        boxes.splice(found, 1);
                    }
                    break;
                case ActionTypes.DELETE:
                    boxes.push(latest.box);
                    break;
                case ActionTypes.MOVE:
                case ActionTypes.RESIZE:
                    if (latest.index !== undefined) {
                        boxes[latest.index] = latest.box;
                    }
                    break;
                default:
            }
            this.setState({ boxes });
        }
    };

    setPositionFunctions = (onStartMovingBox: Function, onStartResizingBox: Function) => {
        this.setState({ onStartMovingBox, onStartResizingBox });
    };

    setCurrentBox = (currentBox: { index: number; box: BoundingBox }) => {
        this.setState({ currentBox });
    };

    finishMovingBox = () => {
        const { currentBox, boxes, history } = this.state;
        if (currentBox.index !== -1) {
            history.push({ action: ActionTypes.MOVE, box: boxes[currentBox.index], index: currentBox.index });
            boxes[currentBox.index] = { ...currentBox.box, show: true };
            this.setState({
                currentBox: { index: -1, box: { coord: [0, 0, 0, 0], label: '' } },
                boxes,
                history,
            });
        }
    };

    render() {
        const { boxes, image, image_size, currentBox, onStartMovingBox, onStartResizingBox } = this.state;
        return (
            <Card className="image-paper">
                <SizeMe monitorHeight>
                    {({ size }) => (
                        <PositionManager
                            setCurrentBox={this.setCurrentBox}
                            currentBox={currentBox}
                            finishMovingBox={this.finishMovingBox}
                            setPositionFunctions={this.setPositionFunctions}
                            imageSize={image_size}
                            boxes={boxes}
                        >
                            {boxes.map((box, index) => (
                                <DropdownPin
                                    key={`${box.coord[0]}${box.coord[1]}${box.label}`}
                                    windowWidth={size.width || 0}
                                    windowHeight={size.height || 0}
                                    imageSize={image_size}
                                    label={box.label}
                                    coord={currentBox.index === index ? currentBox.box.coord : box.coord}
                                    onDelete={() => this.deleteBox(index)}
                                    show={box.show}
                                    index={index}
                                    startMovingBox={onStartMovingBox}
                                    startResizingBox={onStartResizingBox}
                                />
                            ))}
                            <DrawingManager
                                image={image}
                                onPolygonComplete={(box: BoundingBox) => this.appendBox(box, size)}
                                windowWidth={size.width || 0}
                                windowHeight={size.height || 0}
                            />
                        </PositionManager>
                    )}
                </SizeMe>
            </Card>
        );
    }
}

export default withSnackbar(Labeller);
