/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'label';

export const predictionURL = (): string => {
    return `${API_URL}/${api}/prediction`;
};

export const submitURL = (): string => {
    return `${API_URL}/${api}`;
};