/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import axios from 'axios';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import BlockUI from '../elements/BlockUI';
import { predictionURL } from '../../services/labels';

interface OwnState {
    uploadedFile: File | null;
    blocking: boolean;
}

interface OwnProps extends WithSnackbarProps {
    setFileAndPrediction: Function;
}

const INITIAL_STATE: OwnState = {
    uploadedFile: null,
    blocking: false,
};

class UploadFileArea extends Component<OwnProps, OwnState> {
    state = INITIAL_STATE;

    handleImageUpload = (files: File[]) => {
        const { setFileAndPrediction, enqueueSnackbar } = this.props;

        this.setState({ blocking: true });

        const image = files[0];

        this.setState({
            uploadedFile: image,
        });

        const fd = new FormData();
        fd.append('file', image);

        axios
            .post(predictionURL(), fd)
            .then(({ data }) => {
                enqueueSnackbar('Labels generated.', { variant: 'success' });
                setFileAndPrediction(image, data);
            })
            .catch(() => {
                enqueueSnackbar('An error occurred while trying to generate labels.', { variant: 'error' });
                this.setState({ blocking: false });
            });
    };

    render() {
        return (
            <div className="DropzoneArea-root">
                <DropzoneArea
                    acceptedFiles={['image/jpeg']}
                    dropzoneText={'Drag and drop an image here or click'}
                    filesLimit={1}
                    onDrop={this.handleImageUpload}
                    data-testid="dropzone"
                    showAlerts={[]}
                    maxFileSize={10000000}
                />
                <BlockUI blocking={this.state.blocking} />
            </div>
        );
    }
}

export default withSnackbar(UploadFileArea);
