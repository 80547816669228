/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';

import { PredictionResult } from '../../types/predictions';
import UploadFileArea from '../views/UploadFileArea';
import Labeller from '../views/Labeller';
import Nav from '../views/Nav';

interface OwnState {
    labeling: boolean;
    uploadedFile: File | null;
    prediction: PredictionResult | null;
    navAction: () => void;
    undo: () => void;
}

interface OwnProps {}

const INITIAL_STATE: OwnState = {
    labeling: false,
    uploadedFile: null,
    prediction: null,
    navAction: () => {},
    undo: () => {},
};

class Prediction extends Component<OwnProps, OwnState> {
    state = INITIAL_STATE;

    resetLabeller = () => {
        this.setState({labeling : false})
    }

    setFileAndPrediction = (uploadedFile: File, prediction: PredictionResult) => {
        this.setState({ uploadedFile, prediction, labeling: true });
    };

    setNavAction = (navAction: () => void, undo: () => void) => {
        this.setState({ navAction, undo });
    };

    renderContent = () => {
        const { labeling, uploadedFile, prediction } = this.state;
        if (labeling) {
            return <Labeller uploadedFile={uploadedFile} prediction={prediction} setNavAction={this.setNavAction} resetLabeller={this.resetLabeller} />;
        }

        return <UploadFileArea setFileAndPrediction={this.setFileAndPrediction} />;
    };

    render() {
        const { labeling, navAction, undo } = this.state;
        return (
            <>
                <Nav showAction={labeling} action={navAction} cancel={() => this.setState({ labeling: false })} undo={undo} />
                {this.renderContent()}
            </>
        );
    }
}

export default Prediction;
