/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

export const categories = [
    "2nd layer of shingles",
    "3-tab composition shingles",
    "A/C Line-Set",
    "A/C Unit",
    "A/C Unit - Overview",
    "A/C Unit Damaged security cage, dented",
    "A/C Unit Mechanical Damage",
    "AC plywood",
    "Acoustic ceiling tiles",
    "Address",
    "Address verification",
    "Air Blower",
    "Air handler",
    "Air mover",
    "Antenna",
    "Apron",
    "Area rug",
    "Attic",
    "Attic Entrance",
    "Attic Entrance - Damaged",
    "Attic Gable Vent",
    "Attic Gable Vent - Damaged",
    "Attic access ladder",
    "Attic crack in rafter",
    "Attic entrance cover and trim",
    "Attic hole in roof decking",
    "Automobile",
    "Awning",
    "Backsplash",
    "Balcony",
    "Barn - Damaged",
    "Base cabinets",
    "Baseboard",
    "Baseboard and shoe mold",
    "Baseboards - Removed",
    "Baseboards - Rotted",
    "Baseboards - Separating",
    "Baseboards - Water damaged",
    "Basement",
    "Bathroom",
    "Bathroom ventilation fan",
    "Batt insulation",
    "Bed",
    "Bedroom - Overview",
    "Bench",
    "Bi-fold door",
    "Blown-in insulation",
    "Board & batten",
    "Bookshelf",
    "Bottom plate",
    "Bowl",
    "Brick Veneer",
    "Brick Veneer - Cracks in mortar",
    "Brick Veneer - Damaged",
    "Brick Veneer - Displaced",
    "Brick Veneer Wall",
    "Brushes",
    "Bucket",
    "Cabinet - full height",
    "Cabinets",
    "Cabinets - Removed",
    "Cabinets - Separating",
    "Cabinets - Swollen",
    "Cabinets - Water damaged",
    "Cables",
    "Cardboard box",
    "Carpet",
    "Carpet - foot traffic stains",
    "Carpet - water damage",
    "Carpet pad",
    "Carpet stained",
    "Carport",
    "Casing",
    "Ceiling",
    "Ceiling - Damaged hand textured",
    "Ceiling - Water damaged",
    "Ceiling - displaced",
    "Ceiling - popcorn textured",
    "Ceiling fan",
    "Ceiling fan with light",
    "Ceiling hand textured",
    "Ceiling hand textured peeling",
    "Ceiling heavy hand textured",
    "Ceiling joists",
    "Ceiling removed drywall",
    "Ceramic tile floor",
    "Chain Link Fence",
    "Chain Link Fence damaged top rail",
    "Chair",
    "Chair rail",
    "Chandelier",
    "Chandelier - Damaged",
    "Char marks",
    "Chimney",
    "Chimney - Damaged",
    "Chimney - missing siding",
    "Clothes",
    "Clothes dryer vent",
    "Clothes dryer vent dented",
    "Cold air return cover",
    "Couch",
    "Countertop",
    "Countertop - damaged",
    "Crack",
    "Crack in wall",
    "Creased shingles",
    "Cripple",
    "Crown molding",
    "Crown molding - Separating",
    "Curtain",
    "Damaged ceiling",
    "Debris",
    "Deck",
    "Deck - Damaged Lattice",
    "Deck - Lattice",
    "Deck - Water Damage",
    "Dehumidifier",
    "Dent",
    "Desk",
    "Detached carpet",
    "Dishwasher",
    "Dispenser",
    "Door",
    "Door - Dented, punctured",
    "Door - Removed",
    "Door - Rotted",
    "Door Frame",
    "Door casing",
    "Door casing - damaged",
    "Door casing - rotted",
    "Door frame",
    "Door hidge",
    "Door knob",
    "Door stop",
    "Double sink",
    "Downspout",
    "Downspout - Dented",
    "Downspout - Displaced",
    "Downspout - Removed",
    "Downspout - Rusted",
    "Drawers",
    "Dresser",
    "Drip pan",
    "Drip pan - Damaged",
    "Driveway",
    "Driveway - Damaged",
    "Drop ceiling",
    "Drywall",
    "Drywall repair",
    "Ductwork",
    "Electrical line",
    "Excessive granule loss",
    "Exhaust pipe",
    "Exhaust vent",
    "Expansion tank",
    "Exterior door",
    "Fan",
    "Fascia",
    "Fascia - Damaged",
    "Fascia - Removed",
    "Fascia - Rotted",
    "Fascia - Separating",
    "Fascia - Water damaged",
    "Faucet",
    "Felt paper",
    "Fence",
    "Fence - Damaged",
    "Fence - Tree damaged",
    "Fence - Wind damaged",
    "Fence - aluminum fence",
    "Fence - picket fence",
    "Fence - vinyl fence",
    "Fence - wood fence",
    "File cabinets",
    "Fireplace",
    "Fireplace - Damaged",
    "Fireplace hearth",
    "Fireplace mantel",
    "Flag",
    "Flag - Damaged",
    "Flag pole",
    "Flashing",
    "Flashing - Damaged",
    "Flashing - Uplifted",
    "Floor",
    "Floor - Damaged",
    "Floor - Not continuous",
    "Floor decking",
    "Floor joists",
    "Floor stains",
    "Florescent light fixture",
    "Foam",
    "Food",
    "Foundation walls",
    "Foyer",
    "Framing",
    "Framing - Damaged",
    "Framing members",
    "Front Elevation",
    "Furnace",
    "Furnace vent",
    "Furnace vent - Damaged",
    "Furnace vent - Missing",
    "Furnace vent - Rusted",
    "Garage",
    "Gas line",
    "Gazebo",
    "Glasses",
    "Goat roof assist",
    "Granule loss",
    "Grass",
    "Gutter guards - Rusted",
    "Gutter hanger",
    "Gutter screen",
    "Gutters",
    "Gutters - Dented",
    "Gutters - Displaced",
    "Gutters - Hail impacted",
    "Gutters - Removed",
    "Gutters - Rusted",
    "Gutters guards",
    "HVAC roof vent",
    "Hand textured ceiling",
    "Handrail",
    "Hardboard pegboard",
    "Heat/AC register",
    "Hole",
    "Hole in the ceiling",
    "Hose",
    "House Wrap",
    "House Wrap - Damaged",
    "Insulation",
    "Insulation on floor",
    "Interior door",
    "Interior of the upper cabinet",
    "Invoice",
    "Jacuzzi",
    "Judges panel",
    "Kitchen",
    "Kitchen overview",
    "Ladder",
    "Lamp",
    "Laptop",
    "Laundry Room",
    "Lead pipe jack",
    "Left elevation",
    "Light Fixture",
    "Light Fixture - damaged",
    "Light Fixture - removed",
    "Light bulbs",
    "Light pole",
    "Light switch",
    "Lightning Rod",
    "Mailbox",
    "Metal gusset plate",
    "Meter",
    "Meter - Damaged",
    "Meter mast",
    "Microbial growth",
    "Microwave",
    "Mirror",
    "Mirror - Damaged",
    "Missing drywall",
    "Missing ridge caps",
    "Missing shingles",
    "Model/Serial numbers",
    "Modem/Router",
    "Moisture reading - Excessive moisture detected",
    "Moisture reading - No moisture detected",
    "Mold",
    "Molding",
    "Nail",
    "No damage found",
    "Outdoor grill",
    "Outlet",
    "Outlet - Damaged",
    "Oven",
    "Overhead door",
    "Overhead door - Dented",
    "Overhead door - Displaced",
    "Overhead door opener",
    "Pan",
    "Paper scredder",
    "Pergola",
    "Phone, TV, or speaker outlet",
    "Pillar",
    "Pillar - Damaged",
    "Pipe Jack",
    "Pipe Jack Flashing",
    "Pipe Jack Flashing deteriorated",
    "Pipes",
    "Plastic",
    "Plastic turtle vent",
    "Plugs",
    "Plumbing lines",
    "Pole",
    "Pool",
    "Pool - Damaged liner",
    "Porch screen displaced",
    "Power Attic vent",
    "Power Attic vent - Rusted",
    "Power cord",
    "Previous ceiling repair",
    "Previous roof repair",
    "Rafters",
    "Railing",
    "Railing - Damaged",
    "Railing - Removed",
    "Rain diverter",
    "Range",
    "Range - Damaged",
    "Range hood",
    "Rear elevation",
    "Recessed light fixture",
    "Refrigerator",
    "Removed baseboards",
    "Removed carpet",
    "Removed drywall",
    "Removed wood floor",
    "Ridge board",
    "Ridge caps",
    "Right elevation",
    "Roof",
    "Roof - Front slope overview",
    "Roof - Left slope overview",
    "Roof - Metal",
    "Roof - No drip edge",
    "Roof - Overview",
    "Roof - Rear slope overview",
    "Roof - Right slope overview",
    "Roof - Slate shingles",
    "Roof - hole",
    "Roof - lifted shingles",
    "Roof - punctured",
    "Roof 1 Layer",
    "Roof 20 year composition shingles",
    "Roof 3 layers",
    "Roof 30 year laminate shingles",
    "Roof Atlas Chalet shingles",
    "Roof aluminium ridge vent",
    "Roof decking",
    "Roof laminate shingles",
    "Roof rafters",
    "Roof sheathing",
    "Roof valley",
    "Roof valley metal",
    "Roof vent",
    "Rug",
    "Rust",
    "Satellite",
    "Screw",
    "Security alarm",
    "Sewer drain line",
    "Sewer line",
    "Sheathing",
    "Sheathing - Damaged",
    "Shed",
    "Sheet vinyl floor",
    "Shelf",
    "Shelves",
    "Shingle ridge vent",
    "Shingles",
    "Shingles - displaced",
    "Shingles - hail impact",
    "Shingles - lichen growth",
    "Shingles - missing",
    "Shingles heat blisters",
    "Shingles slipping",
    "Shoe mold",
    "Shoe mold stained",
    "Shoes",
    "Shower",
    "Shower tile",
    "Shutters",
    "Shutters - Damaged",
    "Shutters - Displaced",
    "Shutters - Missing",
    "Siding",
    "Siding - Damaged",
    "Siding - Displaced",
    "Siding - Hail impacted",
    "Siding - Metal siding",
    "Siding - Punctured",
    "Siding - Water Damage",
    "Siding - Wood siding",
    "Siding - cracked",
    "Siding - hardboard",
    "Signage",
    "Signage - Damaged",
    "Sink",
    "Sink - Removed",
    "Skylight",
    "Slab foundation",
    "Smoke detector",
    "Soffit",
    "Soffit - Damaged",
    "Soffit - Rotted",
    "Soffit - Separated",
    "Soffit - Water damaged",
    "Solar electric panel",
    "Soot",
    "Speaker",
    "Stained baseboard",
    "Stairs",
    "Stairs - Damaged",
    "Standing water on floor",
    "Steps",
    "Stone veneer",
    "Stone wall",
    "Studs",
    "Subfloor",
    "Subfloor - Damaged",
    "Subfloor - Water damage",
    "Surge protector",
    "Suspended ceiling grid",
    "Swing set",
    "Switch",
    "TV",
    "Table",
    "Table - Damaged",
    "Tack strip",
    "Tackless strip",
    "Tape",
    "Tape measure",
    "Tarp",
    "Tarp - Hole beneath",
    "Tarp - Missing shingles",
    "Tarp - No damage found beneath",
    "Telephone pole",
    "Temporary roof repair",
    "Textured ceiling",
    "Threshold",
    "Threshold - Damaged",
    "Tile Floor",
    "Toe kickboard",
    "Toilet",
    "Towels",
    "Trampoline",
    "Trash can",
    "Tree",
    "Tree - Fallen",
    "Tree - Rotted",
    "Tree - leaning",
    "Tree - on fence",
    "Tree - on house",
    "Tree - on roof",
    "Trim",
    "Tub",
    "Turbine vent",
    "Turbine vent - Damaged",
    "Turbine vent - Rusted",
    "Turtle vent",
    "Upper cabinets",
    "Valve",
    "Vanity",
    "Vanity - Damaged",
    "Vapor barrier",
    "Vent",
    "Vent - Damaged",
    "Vent - Displaced",
    "Vent - Missing",
    "Vent - Rusted",
    "Vinyl blinds",
    "Vinyl plank floor",
    "Vinyl sheet flooring",
    "Vinyl siding",
    "Wall",
    "Wall - Damaged",
    "Wall - Punctured",
    "Wall - Water damaged",
    "Wall framing",
    "Wall insulation",
    "Wall paneling",
    "Wall stains",
    "Wall studs",
    "Wallpaper",
    "Wallpaper - Damaged",
    "Walls",
    "Washing Machine",
    "Water damage",
    "Water heater",
    "Water heater - Damaged",
    "Water stain",
    "Water supply box",
    "Water supply line",
    "Web tie",
    "Wind = 1",
    "Window",
    "Window - Boarded up",
    "Window - Cracked",
    "Window - Damaged",
    "Window - Removed",
    "Window - Wind damaged",
    "Window - shattered",
    "Window Casing",
    "Window Casing - Damaged",
    "Window Casing - Mold growth",
    "Window Casing - Water damaged",
    "Window apron",
    "Window blinds",
    "Window blinds - Damaged",
    "Window frame",
    "Window frame - Damaged",
    "Window frame - Removed",
    "Window frame - Water damaged",
    "Window screens",
    "Window screens - Damaged",
    "Window screens - Displaced",
    "Window sill",
    "Window sill - Damaged",
    "Window sill - Mold growth",
    "Window sill - Water damaged",
    "Window stool & apron",
    "Wires",
    "Wood Floor",
    "Wood Floor - Cupped",
    "Wood Floor - Water damaged",
    "Wood appliance panel",
    "Wood blinds",
    "Wood fascia",
    "Wood floor",
    "Wood panel",
    "Wood trim",
    "Yard",
    "Yard - Damaged landscaping",
    "Island cabinet"
];
