/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FC } from 'react';
import Chip from '@material-ui/core/Chip';
import { Size } from '../../types/predictions';
import BoundingBox from './BoundingBox';

interface OwnProps {
    windowWidth: number;
    windowHeight: number;
    imageSize: Size;
    label: string;
    coord: number[];
    onDelete: (evt: any) => void;
    show?: boolean;
    index: number;
    startMovingBox: Function;
    startResizingBox: Function;
}

const DropdownPin: FC<OwnProps> = (props: OwnProps) => {
    const {
        windowWidth,
        windowHeight,
        imageSize,
        label,
        coord,
        onDelete,
        show,
        startMovingBox,
        index,
        startResizingBox,
    } = props;
    const [showBox, setShowBox] = React.useState<boolean>(!!show);
    const [ref, setRef] = React.useState({ scrollWidth: 0 });

    const box = [
        (coord[0] * windowWidth) / imageSize.width,
        (coord[1] * windowHeight) / imageSize.height,
        (coord[2] * windowWidth) / imageSize.width,
        (coord[3] * windowHeight) / imageSize.height,
    ];

    let offset = (box[0] < box[2] ? box[0] : box[2]) + ref.scrollWidth - windowWidth;
    if (offset < 0) {
        offset = 0;
    }

    const onMoveStart = (evt: any) => {
        evt.stopPropagation();
        evt.preventDefault();
        startMovingBox(evt, index);
    };

    return (
        <>
            {showBox && <BoundingBox box={box} index={index} startResizingBox={startResizingBox} />}
            <Chip
                style={{
                    position: 'absolute',
                    left: box[0] < box[2] ? box[0] : box[2],
                    top: box[1] < box[3] ? box[1] : box[3],
                    transform: `translateX(-${offset}px)`,
                }}
                label={label}
                onDelete={onDelete}
                onClick={() => {
                    setShowBox(!showBox);
                }}
                ref={(c: any) => setRef(c)}
                onMouseDown={onMoveStart}
            />
        </>
    );
};

export default DropdownPin;
