/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

export enum ResizeType {
    TOP_LEFT,
    TOP_RIGHT,
    BOTTOM_LEFT,
    BOTTOM_RIGHT,
}
