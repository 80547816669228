/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FC } from 'react';
import { ResizeType } from '../../types/position';

interface OwnProps {
    create?: boolean;
    box: number[];
    startResizingBox?: Function;
    index?: number;
}

const BoundingBox: FC<OwnProps> = (props: OwnProps) => {
    const { startResizingBox, index } = props;

    const onMoveStart = (evt: any, type: ResizeType) => {
        evt.stopPropagation();
        evt.preventDefault();
        if (startResizingBox) {
            startResizingBox(evt, type, index);
        }
    };

    const { box, create } = props;
    return (
        <div
            className={`box${create ? ' box--create' : ''}`}
            style={{
                position: 'absolute',
                left: box[0] < box[2] ? box[0] : box[2],
                top: box[1] < box[3] ? box[1] : box[3],
                width: Math.abs(box[2] - box[0]),
                height: Math.abs(box[3] - box[1]),
            }}
        >
            {!create && (
                <>
                    <div
                        className="box__resize box__resize--top-left"
                        onMouseDown={(evt) => onMoveStart(evt, ResizeType.TOP_LEFT)}
                    />
                    <div
                        className="box__resize box__resize--top-right"
                        onMouseDown={(evt) => onMoveStart(evt, ResizeType.TOP_RIGHT)}
                    />
                    <div
                        className="box__resize box__resize--bottom-left"
                        onMouseDown={(evt) => onMoveStart(evt, ResizeType.BOTTOM_LEFT)}
                    />
                    <div
                        className="box__resize box__resize--bottom-right"
                        onMouseDown={(evt) => onMoveStart(evt, ResizeType.BOTTOM_RIGHT)}
                    />
                </>
            )}
        </div>
    );
};

export default BoundingBox;
