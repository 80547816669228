/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FC, useCallback, useMemo } from 'react';
import Paper from '@material-ui/core/Paper';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconClose from '@material-ui/icons/Close';
import { throttle } from 'lodash';

import { categories } from '../../types/categories';
import BoundingBox from '../elements/BoundingBox';

interface OwnProps {
    image: string;
    onPolygonComplete: Function;
    windowWidth: number;
    windowHeight: number;
}

interface Coords {
    x: number;
    y: number;
}

const DrawingManager: FC<OwnProps> = (props: OwnProps) => {
    const [currPos, setCurrPos] = React.useState<Coords>({ x: 0, y: 0 });
    const [originalPos, setOriginalPos] = React.useState<Coords>({ x: 0, y: 0 });
    const [finalPos, setFinalPos] = React.useState<Coords>({ x: 0, y: 0 });
    const [drawing, setDrawing] = React.useState<boolean>(false);
    const [box, setBox] = React.useState<number[]>([]);
    const [ref, setRef] = React.useState({
        scrollWidth: 0,
        getBoundingClientRect: () => {
            return { top: 0, left: 0 };
        },
    });
    const [imgRef, setImgRef] = React.useState({
        scrollWidth: 0,
        getBoundingClientRect: () => {
            return { top: 0, left: 0 };
        },
    });
    const { windowWidth, onPolygonComplete } = props;
    let offset = 0;
    if (ref) {
        offset = (originalPos.x < finalPos.x ? originalPos.x : finalPos.x) + ref.scrollWidth - windowWidth;
        if (offset < 0) {
            offset = 0;
        }
    }

    const { image } = props;

    const getCoords = useCallback(
        (evt: any) => {
            var rect = imgRef.getBoundingClientRect();
            let x = evt.clientX - rect.left;
            let y = evt.clientY - rect.top;
            return { x, y };
        },
        [imgRef]
    );

    const onPolygonMove = useCallback(
        (evt: any) => {
            setCurrPos(getCoords(evt));
        },
        [getCoords]
    );

    const onPolygonFinish = useCallback(
        (evt: any) => {
            if (drawing) {
                const { x, y } = getCoords(evt);
                setDrawing(false);
                setFinalPos({ x, y });
                setBox([
                    originalPos.x < x ? originalPos.x : x,
                    originalPos.y < y ? originalPos.y : y,
                    originalPos.x > x ? originalPos.x : x,
                    originalPos.y > y ? originalPos.y : y,
                ]);
            }
        },
        [originalPos, getCoords, drawing]
    );

    const throttledOnMove = useMemo(() => throttle(onPolygonMove, 25), [onPolygonMove]);

    React.useEffect(() => {
        window.addEventListener('mousemove', throttledOnMove);
        window.addEventListener('mouseup', onPolygonFinish);

        // Unmount
        return () => {
            window.removeEventListener('mousemove', throttledOnMove);
            window.removeEventListener('mouseup', onPolygonFinish);
        };
    }, [throttledOnMove, onPolygonFinish]);

    const onPolygonStart = (evt: any) => {
        setOriginalPos(getCoords(evt));
        setDrawing(true);
        setBox([]);
    };

    const shouldAutocomplete = (evt: any) => {
        const found = categories.filter((category) => category.includes(evt.target.value));
        if (found.length === 1 && found[0] === evt.target.value) {
            onPolygonComplete({ coord: box, label: found[0] });
            setBox([]);
        }
    };

    const onAutocompleteChange = (evt: any, newValue: string | null) => {
        if (newValue !== null) {
            onPolygonComplete({ coord: box, label: newValue });
            setBox([]);
        }
    };

    const drawingBox = [originalPos.x, originalPos.y, currPos.x, currPos.y];

    return (
        <>
            <div className="drawing-manager" onMouseDown={onPolygonStart}>
                <img
                    draggable="false"
                    src={image}
                    alt="prediction"
                    className="image-container__image"
                    ref={(c: any) => setImgRef(c)}
                />
                {drawing && <BoundingBox box={drawingBox} create />}
                {box.length === 4 && <BoundingBox box={box} create />}
            </div>
            {box.length === 4 && (
                <Paper
                    style={{
                        position: 'absolute',
                        left: originalPos.x < finalPos.x ? originalPos.x : finalPos.x,
                        top: originalPos.y < finalPos.y ? originalPos.y : finalPos.y,
                        transform: `translateX(-${offset}px)`,
                    }}
                    ref={(c: any) => setRef(c)}
                    className="category-popover"
                >
                    <IconClose onClick={() => setBox([])} className="category-popover__close" />
                    <Autocomplete
                        options={categories}
                        getOptionLabel={(option) => option}
                        onChange={onAutocompleteChange}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                label="Category"
                                placeholder="Category"
                                onChange={shouldAutocomplete}
                                margin="normal"
                            />
                        )}
                    />
                </Paper>
            )}
        </>
    );
};

export default DrawingManager;
