/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FC } from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';

import { AppRoute } from '../types/routes';
import Prediction from './screens/Prediction';

interface OwnProps {}

const Router: FC<OwnProps> = (props: OwnProps) => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={AppRoute.Prediction} component={Prediction} />
                <Redirect to={AppRoute.Prediction} />
            </Switch>
        </BrowserRouter>
    );
};

export default Router;
